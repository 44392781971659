/* eslint-disable max-lines */
export const customer = `
  acceptsMarketing
  id
  displayName
  email
  firstName
  lastName
`;
export const address = `
  address1
  address2
  city
  company
  country
  firstName
  id
  lastName
  province
  phone
  zip
`;

export const lineItem = `
node {
  currentQuantity
  discountAllocations {
    allocatedAmount {
      amount
      currencyCode
    }
    discountApplication {
      value
    }
  }
  quantity
  title
  discountedTotalPrice {
    amount
    currencyCode
  }
  originalTotalPrice {
    amount
    currencyCode
  }
  variant {
    image {
      url,
      altText
    }
    sku
    availableForSale
    barcode
    compareAtPriceV2 {
      amount
    }
    currentlyNotInStock
    id
    image {
      url
      altText
      width
      height
    }
    priceV2 {
      amount
    }
    product {
      id
      handle
      productType
      title
    }
    quantityAvailable
    requiresShipping
    sku
    title
    unitPrice {
      amount
    }
    weight
    weightUnit
  }
}
`;

export const errors = `
  code
  field
  message
`;

export const order = `
  node {
  successfulFulfillments(first:10) {
      trackingInfo(first:10) {
        number,
        url
      }
      trackingCompany
  }
  cancelReason
  canceledAt
  name
  id
  email
  phone
  totalPrice {
    amount
    currencyCode
  }
  currentTotalPrice {
    amount
    currencyCode
  }
  customerUrl
  shippingAddress {
    ${address}
  }
  shippingDiscountAllocations {
    allocatedAmount {
      amount
      currencyCode
    }
    discountApplication {
      value
    }
  }
  statusUrl
  lineItems (first: 50) {
    edges {
      ${lineItem}
    }
  }
  customerLocale
  originalTotalDuties {
    amount
    currencyCode
  }
  originalTotalPrice {
    amount
    currencyCode
  }
  orderNumber
  processedAt
  fulfillmentStatus
  
  totalPriceV2 {
    amount
    currencyCode
  }
  totalShippingPriceV2 {
    amount
    currencyCode
  }
  totalTaxV2 {
    amount
    currencyCode
  }
  totalRefundedV2 {
    amount
    currencyCode
  }
  financialStatus
  subtotalPriceV2 {
    amount
    currencyCode
  }
}
`;

export const customerAccessTokenQuery = `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ${errors}
      }
    }
  }
`;

export const customerCreateQuery = `mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        ${customer}
      }
      customerUserErrors {
        ${errors}
      }
    }
  }
`;

export const getCustomerQuery = (token) => `
  {
    customer(customerAccessToken: "${token}") {
     ${customer}
      defaultAddress {
        ${address}
      }
      addresses(first: 50, reverse: true) {
        edges {
          node {
            ${address}
          }
        }
      }
      orders(first: 250, reverse: true) {
        edges {
          ${order}
        }
      }
    }
  }
`;

export const getCustomerRecoverQuery = `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      ${errors}
    }
  }
}`;

export const getCustomerRecoverURLQuery = `mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $resetUrl, password: $password) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      ${errors}
    }
  }
}`;

export const getPersonalInformationQuery = (token) => `
{
  customer(customerAccessToken: "${token}") {
    defaultAddress {
      ${address}
    }
    ${customer}
  }
}
`;

export const getPersonalAddressesQuery = (token) => `
{
  customer(customerAccessToken: "${token}") {
    addresses(first: 5, reverse: true) {
      edges {
        node {
          ${address}
        }
      }
    }
    defaultAddress {
      ${address}
    }
    ${customer}
  }
}
`;

export const createPersonalAddressQuery = `mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
  customerAddressCreate(
    customerAccessToken: $customerAccessToken
    address: $address
  ) {
    customerAddress {
      ${address}
    }
    customerUserErrors {
      ${errors}
    }
  }
}
`;

export const updatePersonalAddressQuery = `mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
  customerAddressUpdate(
    customerAccessToken: $customerAccessToken
    id: $id
    address: $address
  ) {
    customerAddress {
      ${address}
    }
    customerUserErrors {
      ${errors}
    }
  }
}
`;

export const updateDefaultAddressQuery = `
mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
  customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
    customer {
      id
      defaultAddress {
        ${address}
      }
    }
    customerUserErrors {
      ${errors}
    }
  }
}`;

export const deletePersonalAddressQuery = `mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
  customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
    customerUserErrors {
      field
    }
    deletedCustomerAddressId
  }
}`;

export const customerRecoverQuery = `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      ${errors}
    }
  }
}`;

export const customerResetByUrlQuery = `mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $resetUrl, password: $password) {
    customer {
      ${customer}
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      ${errors}
    }
  }
}`;

export const getOrdersQuery = (token) => `
  {
    customer(customerAccessToken: "${token}") {
      orders(first: 250, reverse: true) {
        edges {
          ${order}
        }
      }
    }
  }
`;

export const getSingleOrderQuery = (token, orderId) => `
  {
    customer(customerAccessToken: "${token}") {
      orders(first: 1, query: "id:${orderId}") {
        edges {
          ${order}
        }
      }
    }
  }
`;
