import React from "react";
import AccountProvider from "./account";
import CartProvider from "./cart";
import ProductFinderProvider from "./product-finder";

const GlobalProvider = ({ children }) => (
    <AccountProvider>
        <CartProvider>
            <ProductFinderProvider>
                {children}            
            </ProductFinderProvider>
        </CartProvider>
    </AccountProvider>
);

export default GlobalProvider;
