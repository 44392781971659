import { STOREFRONT_TOKEN, SITE_URL } from "utils/constants";
import { productFragment, collectionsFragment } from "shopify-queries/storefront/common";

const storefrontQuery = async (query, variables) => {
    try {
        const response = await fetch(`${SITE_URL}/api/2023-04/graphql.json`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Shopify-Storefront-Access-Token": STOREFRONT_TOKEN,
            },
            body: JSON.stringify({
                query,
                variables,
            }),
        });
        const result = await response.json();
        return result;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
    return undefined;
};

export const getProducts = async (handles) => {
    /* Dynamically creates sub-queries like:
        product1: product(handle: "x-y") {
            productInfo...
        }
        product2: product(handle: "x-y") {
            productInfo...
        }
    Since Shopify currently doesn't support fetching multiple products by their handles
    */

    const productsByHandles = handles.map((handle, index) => `
        product${index}: product(handle: "${handle}") {
            ${productFragment}
        }
    `);

    const fetchProducts = await storefrontQuery(`
        query {
            ${productsByHandles}
        }
    `);

    return Object.values(fetchProducts.data || {});
};

export const getMediaImage = async (gIds) => {
    const mediaImages = await storefrontQuery(`
        query {
            nodes(ids: ${JSON.stringify(gIds)}) { 
                id
                ... on MediaImage { 
                image {
                    id 
                    url
               }
            }
          }
        }`);

    return Object.values(mediaImages.data || {});
};

export const getCollections = async (handles) => {
    const collectionsByHandle = handles.map((handle, index) => `
        collection${index}: collectionByHandle(handle: "${handle}") {
            ${collectionsFragment}
        }
    `);
    
    const collections = await storefrontQuery(`
    query { 
        ${collectionsByHandle}
    }`);

    return collections;
};

export default storefrontQuery;
