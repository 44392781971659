/* eslint-disable import/prefer-default-export */
export const productFragment = `
            id
            title
            description
            handle
            productType
            featuredImage {
                url
                altText
            }
            variants (first: 10) {
                edges {
                    node {
                        id
                        title
                        price {
                            currencyCode
                            amount
                        }
                        compareAtPrice {
                            currencyCode
                            amount
                        }
                        image {
                            url
                            altText
                        }
                    }
                }
        }
`;

export const collectionsFragment = `
      handle
      title
      description
      products(first: 50) {
        edges {
          node {
            handle
            title
            metafield(namespace: "sf_quick_product_cre", key: "alter_name") {
                id
                namespace
                key
                value
            }
          }
        }
      }
`;
