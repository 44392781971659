const checkoutFragment = `
      createdAt
      updatedAt
      webUrl
      id
      shippingAddress {
        address1
        address2
        city
        company
        province
      }
      discountApplications(first: 10) {
        edges {
          node {
            ... on DiscountCodeApplication {
              code
              value {
                ... on MoneyV2 {
                  currencyCode
                  amount
                }
              }
            }
          }
        }
      }
      totalPrice {
        currencyCode 
        amount
      }
      lineItemsSubtotalPrice {
        currencyCode
        amount
      }
      lineItems(first: 25) {
        edges {
          node {
            id
            quantity
            customAttributes {
              key
              value
            }
            title
            variant {
              id
              title
              image {
                altText
                url
              }
              compareAtPrice {
                currencyCode
                amount
              }
              price {
                currencyCode
                amount
              }
              product {
                id
                productType
                handle
                metafields(identifiers: 
                  [
                    {namespace: "sf_free_upgrade", key: "block_1_image_1"},
                    {namespace: "sf_free_upgrade", key: "block_1_title_1"},
                    {namespace: "sf_free_upgrade", key: "block_1_description_1"},
                    {namespace: "sf_free_upgrade", key: "block_2_image_2"},
                    {namespace: "sf_free_upgrade", key: "block_2_title_2"},
                    {namespace: "sf_free_upgrade", key: "block_2_description_2"},
                    {namespace: "sf_free_upgrade", key: "block_3_image_3"},
                    {namespace: "sf_free_upgrade", key: "block_3_title_3"},
                    {namespace: "sf_free_upgrade", key: "block_3_description_3"},
                    {namespace: "sf_upsell_products", key: "product_1"},
                    {namespace: "sf_upsell_products", key: "product_2"},
                    {namespace: "sf_upsell_products", key: "product_3"},
                    {namespace: "sf_upsell_products", key: "product_4"},
                    {namespace: "sf_upsell_products", key: "product_5"},
                    {namespace: "sf_upsell_products", key: "product_6"},
                    {namespace: "sf_upsell_products", key: "product_7"},
                    {namespace: "sf_upsell_products", key: "product_8"},
                    {namespace: "sf_upsell_products", key: "product_9"},
                    {namespace: "sf_upsell_products", key: "product_10"}
                  ]) {
                  value
                  key
                  namespace
                }
              }
            }
          }
        }
      }
`;

export const createCheckoutQuery = `
    mutation checkoutCreate($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        checkout {
          ${checkoutFragment}
        }
      }
    }
`;
export const getCheckoutQuery = `query GetCheckout($checkoutId: ID!) {
      node(id: $checkoutId) {
        ... on Checkout {
            ${checkoutFragment}
          }
        }
    }
  `;
export const addLineItemQuery = `
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ${checkoutFragment}
      }
    }
  }
  `;
export const updateLineItemQuery = `
  mutation checkoutLineItemsUpdate($checkoutId:ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ${checkoutFragment}
      }
    }
  }
  `;
export const applyDiscountCodeQuery = `
  mutation checkoutDiscountCodeApplyV2($checkoutId:ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
      checkout {
        ${checkoutFragment}
      }
    }
  }
  `;
export const removeDiscountCodeQuery = `
  mutation checkoutDiscountCodeRemove($checkoutId:ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ${checkoutFragment}
      }
    }
  }
  `;
export const associateCustomerWithCheckoutQueryV2 = `
  mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        webUrl
      }
      customer {
        id
        displayName
        defaultAddress {
          address1
          address2
          city
          country
          firstName
          phone
          lastName
          province
          zip
        }
      }
      checkoutUserErrors {
        message
      }
    }
  }
  `;
export const associateAddressWithCheckoutQueryV2 = `
  mutation checkoutShippingAddressUpdateV2($checkoutId: ID!, $shippingAddress: MailingAddressInput!) {
    checkoutShippingAddressUpdateV2(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
      checkout {
        webUrl
        shippingAddress {
          address1
          address2
          city
          country
          firstName
          phone
          lastName
          province
          zip
        }
      }
      checkoutUserErrors {
        message
      }
    }
  }
  `;
