import React from 'react';
import GlobalProvider from './src/context';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
    return <GlobalProvider> 
             {element} 
    </GlobalProvider>
}

export const shouldUpdateScroll = () => {
    return setTimeout(() => {
        window.scrollTo(0, 0);
    }, 10);
};