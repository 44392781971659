/* eslint-disable id-length */
import { localStorageKey, sessionStorageKey } from "utils/constants"; 
import {
    customerAccessTokenQuery,
    customerCreateQuery,
    getCustomerQuery,
    getPersonalInformationQuery,
    getPersonalAddressesQuery,
    createPersonalAddressQuery,
    updatePersonalAddressQuery,
    updateDefaultAddressQuery,
    deletePersonalAddressQuery,
    customerRecoverQuery,
    customerResetByUrlQuery,
    getOrdersQuery,
    getSingleOrderQuery,
} from "shopify-queries/storefront/account";
import storefrontQuery from "../common"; 

export const setCustomerAccessToken = (token) => window.localStorage.setItem(localStorageKey, !token ? "" : JSON.stringify(token));

export const getAccessToken = () => {
    if (!window.localStorage.getItem(localStorageKey)) return undefined;
    
    const token = JSON.parse(window.localStorage.getItem(localStorageKey));
    if (token?.accessToken && new Date() < new Date(token?.expiresAt)) return token;

    return undefined;
};

export const withAccessToken = (callback) => {
    const token = getAccessToken();

    if (!token) return undefined;

    return callback(token);
};

export const createCustomerAccessToken = (input) => storefrontQuery(customerAccessTokenQuery, { input });

export const createCustomer = (input) => storefrontQuery(customerCreateQuery, { input });

export const getCustomer = () => withAccessToken(async (token) => {
    const customer = await storefrontQuery(getCustomerQuery(token.accessToken));        
    if (customer?.data?.customer) {
        const { displayName, email, firstName, id, lastName } = customer.data.customer;
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify({
            displayName, email, firstName, id, lastName, 
        }));
        return customer.data.customer;
    }

    return undefined;
});

export const getPersonalInformation = () => withAccessToken((token) => storefrontQuery(getPersonalInformationQuery(token.accessToken)));

export const getPersonalAddresses = () => withAccessToken((token) => storefrontQuery(getPersonalAddressesQuery(token.accessToken)));

export const createPersonalAddress = (address) => withAccessToken((token) => storefrontQuery(createPersonalAddressQuery, { customerAccessToken: token.accessToken, address }));

export const updatePersonalAddress = (id, address) => withAccessToken((token) => storefrontQuery(updatePersonalAddressQuery, {
    customerAccessToken: token.accessToken,
    id,
    address,
}));

export const updateDefaultAddress = (addressId) => withAccessToken((token) => storefrontQuery(updateDefaultAddressQuery, { customerAccessToken: token.accessToken, addressId }));

export const deletePersonalAddress = (addressId) => withAccessToken((token) => storefrontQuery(deletePersonalAddressQuery, { customerAccessToken: token.accessToken, id: addressId }));

export const resetCustomerPassword = (email) => storefrontQuery(customerRecoverQuery, email);

export const resetCustomerPasswordByURL = (password, url) => storefrontQuery(customerResetByUrlQuery, { password, url });

export const getOrders = () => withAccessToken((token) => storefrontQuery(getOrdersQuery(token.accessToken)));

export const getSingleOrder = (orderId) => withAccessToken((token) => storefrontQuery(getSingleOrderQuery(token.accessToken, orderId)));
